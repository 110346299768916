.blogPage {
    width: 90%;
    height: 100%;
    padding: 5%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: white;
    min-height: 100vh;
}

.categoryText {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    color: #115FD6;
    line-height: 21px;
}

.headingText {

    margin-top: 15px;
    width: 40%;
    font-size: 52px;
    color: #172B4D;
    line-height: 59px;
}

.posterContainer {
    display: flex;
    font-size: 15px;
    color: #172B4D;
    line-height: 24px;
}

.mainImage {
    margin-top: 36px;
    width: 100%;
}

.bodyContainer {
    margin-top: 48px;
    width: 100%;
    display: flex;
}

.leftContainer {
    width: 65%;
}

.rightContainer {
    width: 30%;
    padding-left: 5%;
}

.blogText {
    font-size: 22px;
    line-height: 33px;
    color: black
}

.blogMainText {
    font-size: 30px;
    line-height: 59px;
    color: #172B4D
}

.popularPost {
    font-size: 14px;
    line-height: 23px;
}

.postContent {
    margin-top: 22px;
}

.bodyImage {
    width: 80px;
    height: 80px
}

.flex {
    display: flex;
    align-items: center;
}

.productText {
    font-size: 20px;
    line-height: 24px;
}

.breakLine {
    margin-top: 42px;
    width: 100%;
    border: 1px solid #172B4D;
}

.getMoreContainer {
    background: #FCA130;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    /* width: 100%; */
    padding: 10%;
    color: white;
}

.getMoreText {
    width: 60%;
    text-align: center;
    font-size: 24px;
    line-height: 36px;
}

.getMoreDetailText {
    margin-top: 20px;
    width: 60%;
    text-align: center;
    font-size: 22px;
    line-height: 33px;
}

.getStartedButton {
    border: none;
    background-color: #DEEBFF;
    color: #091E42;
    padding: 15px;
    border-radius: 3px;
    margin-top: 40px;
}

.thoughtText {
    margin-top: 45px;
    font-size: 20px;
    line-height: 33px;
}

.relatedArticle {
    font-size: 12px;
    margin-top: 45px;
    color: #42526E;
    line-height: 21px;
}

.bottomImage {
    width: 270px;
    height: 203px;
}

.bottomHeadingTest {
    font-size: 24px;
    color: #172B4D;
    line-height: 32px;
}

.bottomBodyText {
    font-size: 16px;
    color: #172B4D;
    line-height: 24px;
}